.mainfooter{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: rgb(26, 26, 26);
}

.topside{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.botside{
    height: 100px;
    align-items: center;
    width: 80%;
    color: #fffef2;
    justify-content: right;
    display: flex;

}

.ralla{
    background-color: #fffef2;
    height: 1px;
}

.ralla2{
    height: 200px;
    width: 1px;
    background-color: #fffef2;
}

.divfooter{
    color: #fffef2;
    text-align: justify;
    display: flex;
    align-items:center;
    flex-direction: column;
}

.divfooter2{
    color: #fffef2;
    text-align: center;
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: space-between;
}

.divicons{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
}


.icons{
    width: 40px  !important;
    height: 40px !important;
    cursor: pointer;
}

.titolsfooter{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5%;
}

.espaiat2{
    margin-left: 20px;
}

.divicons a {
    text-decoration: none;
    color: white;
}
.sliderfoto{
    max-width: 100%;
    width: 100%;
    height: 1000px;
    object-fit: cover;
    opacity: 0.7;
}

.swipper{
    background-color: black;

}


.swiper-button-prev, .swiper-button-next{
    color: white !important;
}

:root {
    --swiper-theme-color: white !important; 
}
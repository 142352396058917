.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2%;
    align-items: center;
}

.adas{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
}
.llista li{
    margin-bottom: 2%;
}
.llista{
    padding: 0;
    margin:0;
}

.primer{
    box-shadow: rgba(0, 0, 0, 0.35) 5px 10px 15px;
    padding: 5%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    border-radius: 10px;
    min-height: 300px;
    transition: all 0.5s ease-in-out;
}

.gridprimer:hover{
    transform: scale(1.1);
}

.primergrid{
    height: 100%;
}

.textinferior{
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    text-align: justify;
}

.primerdiv{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #eeeeee;

}

.leftside{
    width: 95%;
    display: flex;
    flex-direction: column;

}

.titols{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
}
.titol{
    font-size: 25px;
    font-weight: bold;
    color:black;
    display: flex;
    margin-top:5%;


}
.quadrats{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3%;

}
.primerquadrat{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divicona{
    width: 20%;
}
.icona{
    max-width: 100%;
    max-height: 100%;
    height: 70px !important;
    width: 70px !important;
    object-fit: cover;
    background-attachment: scroll !important;

}

.subtext{
    text-align: justify;
    display: flex;
    color:black;
    margin-top: 7%;
    height: 100px;

}

.segonslider{
    width: 100%;
    
}

.segondiv{
    width: 100%;
    overflow: hidden;
    height: 500px;
    background-color: black;
    position: relative;


}
.slider2{
    object-fit: contain;
    width: 100%;
    position: relative;

}
.swipper2{

}

.tipussensors{
    filter: invert(1);
    width: 100px !important;
    height: 100px !important;
}

.divnegre{
    background:rgb(26, 26, 26);
    width: 180px;
    height: 180px;
    border-radius: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.tipus1{
    width: 75%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px !important;
    height: 500px;
    border-radius: 5px;
    padding: 2%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    justify-content: space-around;
}

.tipus1:hover{
    transform: scale(1.1);
}

.titolssensors{
    font-weight: bold;
    font-size: 25px;

}

.ralla3{
    transition: all 1s ease-in-out;
    margin-top: 4%;
    height: 2px;
    background: rgb(26, 26, 26);
}
.menu{
    justify-content: space-around;
    z-index: 50;
    width: 100%;
    color: rgb(0, 0, 0);
    transition: all 0.5s ease-in-out;
    position: fixed;
}

.displayflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logodiv{
    max-height: 100%;
    max-width: 100%;
    height: 100%;
}
.logoimg{
    transition: all 0.5s ease-in-out;

}

.div-right{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 700;
}

.bottomborder{
    cursor: pointer;
}
.encapsulament{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.topdeltot{
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-evenly;
    z-index: 99;
    position: absolute;
    width: 100%;
    transition: all 1s ease-in-out;
    font-size: 18px;
    margin-top: 1%;
    top: 0;
}

.top{
    display: flex;
    align-items: center;
    background-color: transparent;
}
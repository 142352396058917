.com{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8%;
}


.divimgcom{
    height: 400px;
}
.imgcom{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}   

.subtextcom{
    margin-top: 5%;
}
.titolcom{
    font-weight: bold;
    font-size: 21px;
}

.textcom{
    text-align: justify;
    margin-top: 50px;
    margin-bottom: 50px;
}

.tencar{
    width: 70px;
    height: 70px;
    position: absolute;
    right: 90px;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: white;
}
.close2{
    fill: white !important; 
    font-size: 4rem !important;
    padding:2% !important;

}

.div-clicked-img {
    max-width: 50% !important;
    height: auto !important;
    object-fit: contain !important;
    width: 100% !important;
}
.obert {
    display: flex;
}


.esquerra_img {
    fill: white  !important;
    transition: transform .2s  !important; /* Animation */
    margin-left: 1%;
  }
  
  .dreta_img {
    fill: white !important;
    transition: transform .2s !important; /* Animation */
    margin-right: 1%;
  
  }
  .arrows2{
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }
.petit{
    position: absolute;
    align-items: center;
    justify-content: space-evenly;
    z-index: 11;
    background: white;
    height: 100%;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    min-width: 350px;
}

.gran{
    width: 100vw;
    background: black;
    z-index: 12;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 100%;
}

.nav2{
    font-size: 20px;
    font-weight: 600;
    cursor:pointer;
}
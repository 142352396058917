.preguntes{
    width: 80%;
}
.mainpreguntes{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;

}
.pregunta{
    font-weight: 700;
    font-size: 25px;
}
.item{
    display: flex;
    text-align: justify;
    margin-top: 8%;
}

.numero2{
    position: relative;
    display: inline-block;
    font-family: Rajdhani, sans-serif;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #e4e1e5;
    margin-right: 20px;
}

.resposta{
    margin-top: 5%;
    font-size: 18px;
}
.ralla4{
    width: 5%;
    height: 1px;
    background-color: black;
    margin-right: 3%;
}
.motius{
    width: 100%;
}
.itemgrid2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.perque{
    margin-bottom: 2.5%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.ralla5{
    width: 65%;
    height: 1px;
    background-color: black;
    margin-left: 3%;
}

.undertext{
    text-align: justify;
}
.flexsuperior{
    width: 25%;
}

.smalltitles{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 5%;
    text-align: justify;
}
.icon{
    width: 100%;
    max-width: 70px;
}
.iconfoto{
    max-width: 100%;
    height: 70px;
    width: 70px;
}

.flex{
    display: flex;
    flex-direction: column;
    margin-left: 5%;

}

.flex2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 85px;
}

.text{
    width: 100%;
    text-align: justify;
    font-size: 18px;
}
.fotodiv1{
    width: 80%;
}

.foto{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    margin-top: 10%;
}

.itemfoto{
    max-width: 100%;
    box-shadow: none;
    cursor: pointer;

}

.itemfoto img{
    width: 100%;
    border-radius: 10px;
}

.tittles{
    font-size: 21px;
    font-weight: 600;
}

.rodona{
    position: relative;
    display: inline-block;
    margin-top: -4px;
    font-family: Rajdhani, sans-serif;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    color: #e4e1e5;
    margin-right: 20px;
}
.englobat {
    margin-top: 10%;

}

.textimatge{
    position: absolute;
    top: 30%;
    left: 10%;
}
.textimatge span{
    font-size: 65px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.861);
}

.botohora{
    cursor:pointer;
    background-color: white;
    height: 50px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 8%;
}

.rallaboto{
    height:1px;
    background-color: black;
    margin-right: 10px;
    transition: all 0.25s ease-in-out;
}



.div-imatgess{
    position: relative;
    padding-top: 195px;
    margin-bottom: 3%;
}

.imatgess img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;

}
.titol-imatges{
    font-size: 50px;
    font-family: 'Gotham Bold', sans-serif;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column; 
}

.bottomhr{
    margin-bottom: 3%;
}

.div-clicked{
    width: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 50%;
    margin: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #000000cf;
    z-index: 110;
    display: none;
}

.obert {
    display: flex;
}
  .imgmaquina{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .texttexa p{
    font-size: 18px;
  }
.textportada{
    position: absolute;
    z-index: 10;
    color: black;
    right: 0;
    margin: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    justify-content: center;
    bottom: 200px;
}

.video{
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
    position: relative;

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

}
.slider{
    overflow: hidden;
    z-index: 0;
    position: relative;
    top:0;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

}
.spantext{
    font-size: 60px;
    width: 100%;
    font-weight: 800;
    display: flex;
    justify-content: center;
}
.hora{
    display: block;
    height: 20px;
    width: 25%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 20px;
    margin-top: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 2px solid white;
    border-color: rgb(255, 255, 255);
    color:rgb(0, 0, 0);
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.hora:hover{
    border: 2px solid white;
    color:#000000;
    background-color: transparent;
    font-size: 20px;

}

.botohora2{
    cursor:pointer;
    background-color: white;
    height: 50px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 5px;
}

.rallaboto2{
    height:1px;
    background-color: black;
    margin-right: 10px;
    transition: all 0.25s ease-in-out;
}



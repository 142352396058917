.primer2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.tipus{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

}
.fons{
    background-color: #ffffff;
    border-radius: 10px;
    padding:5%;
    min-height: 450px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 15px !important;


}

.titolstipus{
    text-align: justify;
    font-weight: 700;
    margin-top: 10%;
}
.subtitolstipus{
    text-align: justify;
    margin-top: 10%;
}

.fotoadas{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.boto3{
    height: 100%;
    width: 100%;
    background-color: #005892;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;

}
.link a{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 20%;
    color: white;
    text-decoration: none;
}
.link{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
}
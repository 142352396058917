.politica{
    width: 80%;
    margin-top: 2%;
    margin-bottom: 5%;
}
.textgran{
    text-align: justify;
    font-size: 20px;
}
.mainpolitica{
    width: 100%;
    display: flex;
    justify-content: center;

}